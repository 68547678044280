import React, {useState}  from "react";
import {Helmet} from "react-helmet";

function App() {

  
  const [light, setlight] = useState(false);

  const onCheck = () =>{
    setlight(!light);
  }


  return (
    <div class={(light)?("bg-light"):("bg-dark")}>
      <Helmet>
        <title>Minecraft: The Best Thing</title>
        <meta name="description" content="This experiment has ended. Check back soon for results!"></meta>
        <meta name="author" content="BMCT Dev Team"></meta>
        <meta name="keywords" content="minecraft best thing mc tom scott dukky vote dream" />
      </Helmet>
      <div class={(light)?(""):("overlay")}>
      <div class="container-fluid">
          <div class="row">
            <div class="center adj">
              <input id="toggletheme" onClick={onCheck} type="image" src={(light)?("https://i.imgur.com/rvXYKZ9.png"):("https://i.imgur.com/OWXmoUk.png")} alt="toggle theme"></input>
            </div>
            <div class="center adj">
              <a href="https://github.com/KyngK/bestmcthing"><p style={{ 'max-width': '100%' }}class={(light)?("txt-light"):("txt-dark")}>GitHub Page</p></a>
            </div>
            <div class="center adj">
              <a target="_blank" rel="noopener noreferrer" href="https://youtu.be/Y6uToji2bkg"><img id="YT" alt="YouTube video" src="https://www.freepnglogos.com/uploads/youtube-play-red-logo-png-transparent-background-6.png"/></a>
            </div>
	        </div>
          
	      <div class='vcenter'>
          <p style={{ 'font-size': '100pt' }}class={(light)?("txt-light"):("txt-dark")}>WOW!</p>
          <p style={{ 'font-size': '24pt'}} class={(light)?("txt-light"):("txt-dark")}>After just a week, we blew our target of 15,000 votes out of the water with over 400,000 votes! Thanks to everyone who participated, and stay tuned for the results on <a href="https://www.youtube.com/@DukkyMC"><p style={{ 'font-size': '24pt', 'max-width': '100%' }} class={(light)?("txt-light"):("txt-dark")}>Dukky's Channel</p></a>!</p>
	      </div>
      </div>
      </div>
    </div>
  );
}

export default App;
